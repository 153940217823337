<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <div v-if="config.type === 'add' || config.type === 'edit'">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="类型" prop="type">
              <a-select
                :disabled="true"
                placeholder="请输入"
                v-model="form.type"
                class="formInputWidth"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="标题" prop="title">
              <a-input
                :maxLength="50"
                class="formInputWidth"
                placeholder="请输入"
                v-model="form.title"
              />
            </a-form-model-item>
            <a-form-model-item label="展示顺序" prop="sort">
              <a-input-number
                placeholder="请输入"
                class="formInputWidth"
                id="inputNumber"
                v-model="form.sort"
                :min="1"
                @change="onOrderChange"
                :precision="0"
              />
            </a-form-model-item>
            <a-form-model-item label="客户logo" prop="logo">
              <div class="upload_Content">
                <div class="notice_text">
                  建议尺寸：551px*514px，支持格式：PNG、JPG或JPEG，单个文件不超10M
                </div>
                <UploadTag
                    @getImageUrl="getLogoImageUrl"
                    :config="uploadLogoConfig"
                ></UploadTag>
              </div>
            </a-form-model-item>
            <a-form-model-item label="案例" prop="caseSwitch">
              <a-switch v-model="form.caseSwitch" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="description" v-if="form.caseSwitch">
              <a-textarea
                :maxLength="100"
                class="formInputWidth"
                v-model="form.description"
                placeholder="请输入"
                @change="descriptionOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ desLength }}/100</span
              >
            </a-form-model-item>
            <a-form-model-item label="业务痛点" prop="businessPain" v-if="form.caseSwitch">
              <a-textarea
                :maxLength="1000"
                class="formInputWidth"
                v-model="form.businessPain"
                placeholder="请输入"
                @change="businessPainOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ busLength }}/1000</span
              >
            </a-form-model-item>
            <a-form-model-item label="解决方案" prop="solution" v-if="form.caseSwitch">
              <a-textarea
                :maxLength="1000"
                class="formInputWidth"
                v-model="form.solution"
                placeholder="请输入"
                @change="solutionOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ solLength }}/1000</span
              >
            </a-form-model-item>
            <a-form-model-item label="封面上传" prop="cover" v-if="form.caseSwitch">
              <div class="upload_Content">
                <div class="notice_text">
                  建议尺寸：551px*514px，支持格式：PNG、JPG或JPEG，单个文件不超10M
                </div>
                <UploadTag
                  @getImageUrl="getImageUrl"
                  :config="uploadConfig"
                ></UploadTag>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import UploadTag from "../../../components/uploadTag.vue";
import { contentManageApi } from "@/api/contentManage.js";

export default {
  components: {
    UploadTag,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
      typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
      ],
      spinning: false,
      confirmLoading: false,
      uploadConfig: {
        barrelType: "public",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
      },
      uploadLogoConfig: {
        barrelType: "public",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
      },
      editId: null,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      solLength: 0,
      busLength: 0,
      desLength: 0,
      form: {
        type: 4,
        title: "",
        sort: "",
        description: "",
        businessPain: "",
        solution: "",
        cover: "",
        logo: "",
        caseSwitch: false
      },

      rules: {
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        sort: [
          {
            required: true,
            message: "展示顺序不能为空",
            trigger: "blur",
          },
        ],
        logo: [
          {
            required: true,
            message: "logo 不能为空",
            trigger: "blur",
          },
        ],
        caseSwitch: [
          {
            required: true,
            message: "案例不能为空",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "描述不能为空",
            trigger: "blur",
          },
        ],
        businessPain: [
          {
            required: true,
            message: "业务痛点不能为空",
            trigger: "blur",
          },
        ],
        solution: [
          {
            required: true,
            message: "解决方案不能为空",
            trigger: "blur",
          },
        ],
        cover: [
          {
            required: true,
            message: "封面不能为空",
            trigger: "blur",
          },
        ],

      },
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},
  methods: {
    initModal() {
      if (this.config.type === "add") {
        return;
      } else {
        this.getDetail();
      }
    },
    //新增案例
    addCaseContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
      };
      contentManageApi.addCaseContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //更新案例
    editCaseContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        contentId: this.config.id,
        rowVersion: this.config.record.rowVersion,
        id: this.editId,
      };
      contentManageApi.editCaseContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //获取编辑详情
    getDetail() {
      this.spinning = true;
      let id = this.config.id;
      contentManageApi.getDetail(id).then((res) => {
        if (res.success) {
          this.editId = res.data.id;
          this.form.title = res.data.title;
          this.form.sort = res.data.sort;
          this.form.description = res.data.description;
          this.form.solution = res.data.solution;
          this.form.businessPain = res.data.businessPain;
          this.uploadConfig.fileList.push({
            name: res.data.cover.substring(res.data.cover.lastIndexOf("/") + 1),
            uid: res.data.cover,
            status: "done",
            url: res.data.cover,
          });
          this.form.caseSwitch = res.data.caseSwitch
          console.log(res)
          if (res.data.logo) {
            this.form.logo = res.data.logo
            this.uploadLogoConfig.fileList.push({
              name: res.data.logo.substring(res.data.cover.lastIndexOf("/") + 1),
              uid: res.data.logo,
              status: "done",
              url: res.data.logo,
            })
          }
          this.form.cover = res.data.cover;
          this.$nextTick(() => {
            this.desLength = this.form.description.length;
            this.busLength = this.form.businessPain.length;
            this.solLength = this.form.solution.length;
          });
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    //上传组件的回调结果
    getImageUrl(val, fileList) {
      console.log(val, fileList)
      this.form.cover = val;
      this.uploadConfig.fileList = fileList;
    },
    getLogoImageUrl(val, fileList) {
      console.log('val',val)
      console.log(val, fileList)
      this.form.logo = val
      this.uploadLogoConfig.fileList = fileList
    },
    // 描述输入事件
    descriptionOnchange() {
      this.$nextTick(() => {
        this.desLength = this.form.description.length;
      });
    },
    // 业务痛点输入事件
    businessPainOnchange() {
      this.$nextTick(() => {
        this.busLength = this.form.businessPain.length;
      });
    },
    // 解决方案输入事件
    solutionOnchange() {
      this.$nextTick(() => {
        this.solLength = this.form.solution.length;
      });
    },

    //顺序内容变化事件
    onOrderChange(val) {
      this.sort = val;
    },

    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addCaseContent();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editCaseContent();
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_Content {
  min-height: 160px;

  // background-color: aqua;
}
.formC .formContent {
  padding-left: 110px;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>