<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
        :confirm-loading="confirmLoading"
    >
     <a-spin :spinning="spinning">
      <div v-if="config.type === 'add' || config.type === 'edit'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          class="formContent"
        >
          <a-form-model-item label="类型" prop="type">
             <a-select
                :disabled="true"
                placeholder="请输入"
                v-model="form.type"
                class="formInputWidth"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
          </a-form-model-item>

          <a-form-model-item label="详情" prop="details">
            <RichText
              v-model="this.form.details"
              :config="richTextConfig"
              @onchange="onRTChange"
              v-if="richShow"
            ></RichText>
          </a-form-model-item>
        </a-form-model>
      </div>
       </a-spin>
    </a-modal>
  </div>
</template>

<script>
import RichText from "../../../components/richText.vue";
import { contentManageApi } from "@/api/contentManage.js";

export default {
  components: {
    RichText,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
          typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
      ],
      spinning:false,
        confirmLoading:false,
      richShow: true,

   
      editId: null,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      detLength: 0,
      form: {
        type:7,
        details: "",
      },
      richTextConfig: {
        width: "800px",
        height: "400px",
        content: null,
        placeholder: "不超过3000个字符",
        excludeMenus: ["video"],
      },
      rules: {
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],

        details: [
          {
            required: true,
            message: "详情不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
      },
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},

  methods: {
    onRTChange(val) {
      this.form.details = val;
    },

    initModal() {
      if (this.config.type === "add") {
        //   this.getTypeList()
      } else {
        this.getDetail();
      }
    },
    //新增用户服务协议
    addServiceAgreement() {
      this.confirmLoading=true
      let params = {
        ...this.form,
        sort: "-",
      };
      contentManageApi.addServiceAgreement(params).then((res) => {
        if (res.success) {
            this.confirmLoading=false
          this.$emit("innerHandleOk");
        } else {
           this.confirmLoading=false
          this.$message.error(res.errorMsg);
        }
      });
    },
    //更新用户服务协议
    editServiceAgreement() {
       this.confirmLoading=true
      let params = {
        ...this.form,
        contentId: this.config.id,
        rowVersion: this.config.record.rowVersion,
        id: this.editId,
        sort: "-",
      };
      contentManageApi.editServiceAgreement(params).then((res) => {
        if (res.success) {
           this.confirmLoading=false
          this.$emit("innerHandleOk");
        } else {
           this.confirmLoading=false
          this.$message.error(res.errorMsg);
        }
      });
    },
    //获取编辑详情
    getDetail() {
      this.spinning=true;
      let id = this.config.id;
      this.richShow = false;
      contentManageApi.getDetail(id).then((res) => {
        if (res.success) {
          this.editId = res.data.id;
          this.form.details = res.data.details;
          this.richTextConfig.content = res.data.details;
          this.richShow = true;
          this.spinning=false
        } else {
          this.$message.error(res.errorMsg);
          this.richShow = true;
            this.spinning=false
        }
      });
    },

    // 详情输入事件
    textOnchange() {
      if (this.form.details.length > 500) {
        this.form.details = this.form.details.substring(0, 500);
      }
      this.$nextTick(() => {
        this.detLength = this.form.details.length;
      });
    },

    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addServiceAgreement();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editServiceAgreement();
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.formContent {
  margin-left: -110px;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 20px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>