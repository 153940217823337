<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <div v-if="config.type === 'add' || config.type === 'edit'">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="类型" prop="type">
              <a-select
                :disabled="true"
                placeholder="请输入"
                v-model="form.type"
                class="formInputWidth"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="开户名称" prop="accountName">
              <a-input
                :maxLength="30"
                class="formInputWidth"
                placeholder="请输入"
                v-model="form.accountName"
              />
            </a-form-model-item>
            <a-form-model-item label="开户银行" prop="accountBank">
              <a-input
                :maxLength="30"
                class="formInputWidth"
                placeholder="请输入"
                v-model="form.accountBank"
              />
            </a-form-model-item>
            <a-form-model-item label="银行账号" prop="bankAccount">
              <a-input
                :maxLength="37"
                class="formInputWidth"
                placeholder="请输入"
                v-model="form.bankAccount"
              />
            </a-form-model-item>
            <a-form-model-item label="说明" prop="explain">
              <a-textarea
                :maxLength="100"
                class="formInputWidth"
                v-model="form.explain"
                placeholder="请输入"
                @change="explainOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ expLength }}/100</span
              >
            </a-form-model-item>
            <a-form-model-item label="温馨提醒" prop="tips">
              <a-textarea
                :maxLength="500"
                class="formInputWidth"
                v-model="form.tips"
                placeholder="请输入"
                @change="tipsOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ tipsLength }}/500</span
              >
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { contentManageApi } from "@/api/contentManage.js";

export default {
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
      typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
      ],
      spinning: false,
      confirmLoading: false,
      editId: null,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      tipsLength: 0,
      expLength: 0,
      form: {
        type: 8,
        accountName: "",
        accountBank: "",
        bankAccount: "",
        tips: "",
        explain: "",
      },

      rules: {
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
        accountName: [
          {
            required: true,
            message: "开户名称不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        accountBank: [
          {
            required: true,
            message: "开户银行不能为空",
            trigger: "blur",
          },
        ],
        bankAccount: [
          {
            required: true,
            message: "银行账号不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        explain: [
          {
            required: true,
            message: "说明不能为空",
            trigger: "blur",
          },
        ],
        tips: [
          {
            required: true,
            message: "温馨提示不能为空",
            trigger: "blur",
          },
        ],
      },
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {
    ["form.bankAccount"](val) {
      this.$nextTick(() => {
        this.form.bankAccount = val
          .replace(/\D/g, "")
          .replace(/....(?!$)/g, "$& ");
      });
    },
  },
  methods: {
    initModal() {
      if (this.config.type === "add") {
        return;
      } else {
        this.getDetail();
      }
    },
    //新增汇款账号
    addAccountNumber() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        sort: "-",
        note: this.form.explain,
        bankAccount: this.form.bankAccount.replace(/\s*/g, ""),
      };
      contentManageApi.addAccountNumber(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //更新汇款账号
    editAccountNumber() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        bankAccount: this.form.bankAccount.replace(/\s*/g, ""),
        contentId: this.config.id,
        rowVersion: this.config.record.rowVersion,
        id: this.editId,
        sort: "-",
        note: this.form.explain,
      };
      contentManageApi.editAccountNumber(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //获取编辑详情
    getDetail() {
      this.spinning = true;
      let id = this.config.id;
      contentManageApi.getDetail(id).then((res) => {
        if (res.success) {
          this.editId = res.data.id;
          this.form.accountName = res.data.accountName;
          this.form.accountBank = res.data.accountBank;
          this.form.bankAccount = res.data.bankAccount;
          this.form.tips = res.data.tips;
          this.form.explain = res.data.note;
          this.$nextTick(() => {
            this.expLength = this.form.explain.length;
            this.tipsLength = this.form.tips.length;
          });
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },

    // 描述输入事件
    explainOnchange() {
      this.$nextTick(() => {
        this.expLength = this.form.explain.length;
      });
    },
    // 温馨提醒输入事件
    tipsOnchange() {
      this.$nextTick(() => {
        this.tipsLength = this.form.tips.length;
      });
    },

    //顺序内容变化事件
    onOrderChange(val) {
      this.order = val;
    },

    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addAccountNumber();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editAccountNumber();
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.formContent {
  padding-left: 110px;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>