<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <div v-if="config.type === 'add' || config.type === 'edit'">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="类型" prop="type">
              <a-select
                :disabled="true"
                placeholder="请输入"
                v-model="form.type"
                class="formInputWidth"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="标题" prop="title">
              <a-input
                :maxLength="50"
                class="formInputWidth"
                placeholder="请输入"
                v-model="form.title"
              />
            </a-form-model-item>
            <a-form-model-item label="展示顺序" prop="sort">
              <a-input-number
                placeholder="请输入"
                class="formInputWidth"
                id="inputNumber"
                v-model="form.sort"
                :min="1"
                @change="onOrderChange"
                :precision="0"
              />
            </a-form-model-item>
            <a-form-model-item label="详情" prop="details">
              <a-textarea
                class="formInputWidth"
                v-model="form.details"
                placeholder="请输入"
                @change="textOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ detLength }}/500</span
              >
            </a-form-model-item>
            <a-form-model-item label="封面上传" prop="cover">
              <div class="upload_Content">
                <div class="notice_text">
                  建议尺寸：1920px*650px，支持格式：PNG、JPG或JPEG，单个文件不超10M
                </div>
                <UploadTag
                  :config="uploadConfig"
                  @getImageUrl="getImageUrl"
                ></UploadTag>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { contentManageApi } from "@/api/contentManage.js";
import UploadTag from "../../../components/uploadTag.vue";
export default {
  components: {
    UploadTag,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: null,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
      spinning: false,
      confirmLoading: false,
      typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
      ],
      uploadConfig: {
        barrelType: "public",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
      },
      editId: null,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      detLength: 0,
      form: {
        type: 1,
        title: "",
        sort: "",
        details: "",
        cover: "",
      },

      rules: {
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        sort: [
          {
            required: true,
            message: "展示顺序不能为空",
            trigger: "blur",
          },
        ],
        details: [
          {
            required: true,
            message: "详情不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        cover: [
          {
            required: true,
            message: "封面不能为空",
            trigger: "blur",
          },
        ],
      },
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},
  methods: {
    initModal() {
      if (this.config.type === "add") {
        //   this.getTypeList()
      } else {
        this.getDetail();
      }
    },
    //新增顶部内容
    addTopContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
      };
      contentManageApi.addTopContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //更新顶部内容
    editTopContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        contentId: this.config.id,
        rowVersion: this.config.record.rowVersion,
        id: this.editId,
      };
      contentManageApi.editTopContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //获取编辑详情
    getDetail() {
      this.spinning = true;
      let id = this.config.id;
      contentManageApi.getDetail(id).then((res) => {
        if (res.success) {
          this.editId = res.data.id;
          this.form.title = res.data.title;
          this.form.sort = res.data.sort;
          this.form.details = res.data.details;
          this.uploadConfig.fileList.push({
            name: res.data.cover.substring(res.data.cover.lastIndexOf("/") + 1),
            uid: res.data.cover,
            status: "done",
            url: res.data.cover,
          });
          this.form.cover = res.data.cover;
          this.$nextTick(() => {
            this.detLength = this.form.details.length;
          });
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    //上传组件的回调结果

    getImageUrl(val,fileList) {
      this.form.cover = val;
      this.uploadConfig.fileList = fileList;
    },
    // 详情输入事件
    textOnchange() {
      if (this.form.details.length > 500) {
        this.form.details = this.form.details.substring(0, 500);
      }
      this.$nextTick(() => {
        this.detLength = this.form.details.length;
      });
    },
    //顺序内容变化事件
    onOrderChange(val) {
      this.sort = val;
    },

    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addTopContent();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editTopContent();
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_Content {
  min-height: 160px;
}
.formContent {
  padding-left: 110px;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>