<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div v-if="config.type === 'del'">
        <div class="modal_text">
          <a-icon theme="twoTone" type="question-circle" />
          <span class="modal_logo">是否确认删除?</span>
        </div>
        <div class="modal_text modal_stext">删除后无法恢复</div>
      </div>
      <div v-if="config.type === 'add'">
        <div style="margin-left: 80px">
          <span style="color: red">*</span> 类型：
          <a-select style="width: 300px" v-model="type">
            <a-select-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { contentManageApi } from "@/api/contentManage.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
      typeList: [],
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},
  methods: {
    initModal() {
      if (this.config.type === "add") {
        this.$nextTick(() => {
          this.getTypeList();
        });
      }
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleOk() {
      if (this.config.type === "add") {
        if (this.type) {
          this.$emit("handleOk", this.type);
        } else {
          this.$message.warn("类型选择不能为空");
        }
      } else {
        this.deleteContent();
      }
    },
    deleteContent() {
      let id = this.config.id;
      contentManageApi.deleteContent(id).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    getTypeList() {
      contentManageApi.getTypeList().then((res) => {
        if (res.success) {
          // this.typeList = res.data.records;
          let arr = [];
          let temp = res.data;
          if (res.data) {
            Object.keys(temp).forEach((v) => {
              arr.push({ name: temp[v], value: v });
            });
            this.typeList = arr;
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_text {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
  .modal_logo {
    margin-left: 10px;
    margin-top: -6px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 40px;
  padding-top: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>