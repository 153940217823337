import axios from "@/config/http";

export const contentManageApi = {
  // 获取内容维护table数据
  getTableData(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/content", {
      params,
    });
  },
  // 获取类型下拉列表
  getTypeList() {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/content/type"
    );
  },
  //新增顶部内容
  addTopContent(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/top-content",
      params
    );
  },

  //更新顶部内容
  editTopContent(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/top-content",
      params
    );
  },

  //新增产品动态
  addProductNew(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/product-dynamic",
      params
    );
  },

  //更新产品动态
  editProductNew(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/product-dynamic",
      params
    );
  },
  //新增核心能力
  addCorePower(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/core-ability",
      params
    );
  },

  //更新核心能力
  editCorePower(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/core-ability",
      params
    );
  },
  //新增案例
  addCaseContent(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/case",
      params
    );
  },

  //更新案例
  editCaseContent(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/case",
      params
    );
  },
  //新增服务
  addServiceContent(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/service",
      params
    );
  },

  //更新服务
  editServiceContent(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/service",
      params
    );
  },
  //新增用户好评
  addUserPraise(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/user-praise",
      params
    );
  },

  //更新用户好评
  editUserPraise(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/user-praise",
      params
    );
  },
  //新增用户服务协议
  addServiceAgreement(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/user-service-agreement",
      params
    );
  },

  //更新用户服务协议
  editServiceAgreement(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/user-service-agreement",
      params
    );
  },
  //新增汇款账号
  addAccountNumber(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/account-number",
      params
    );
  },

  //更新汇款账号
  editAccountNumber(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/account-number",
      params
    );
  },
  //新增其他
  addOtherContent(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/content/other",
      params
    );
  },

  //更新其他
  editOtherContent(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/content/other",
      params
    );
  },

 //新增os厘米
 addOsIntroduce(params) {
  return axios.post(
    "/cloud-platform-backend-facade/front-api/v1/content/os-introduction",
    params
  );
},

//更新os厘米
editOsIntroduce(params) {
  return axios.put(
    "/cloud-platform-backend-facade/front-api/v1/content/os-introduction",
    params
  );
},

  //获取详细信息
  getDetail(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/content/${params}`
    );
  },
  //删除内容维护
  deleteContent(params) {
    return axios.delete(
      `/cloud-platform-backend-facade/front-api/v1/content/${params}`
    );
  },
};
