<template>
  <div class="rich_contant">
    <div
      :style="{ width: config.width, height: config.height }"
      id="div1"
    ></div>
    <!-- <div class="notice_number">
      <span>已输入{{ detLength }}/3000</span>
    </div> -->
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  name: "richText", //富文本组件
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          width: "800px", //富文本高度
          height: "200px", //富文本高度
          content: null, //回显内容
          placeholder: "请输入", //默认提示文本
          excludeMenus: [], //剔除的菜单项,具体有哪些菜单看官方文档https://www.wangeditor.com/doc/pages/03-%E9%85%8D%E7%BD%AE%E8%8F%9C%E5%8D%95/01-%E8%87%AA%E5%AE%9A%E4%B9%89%E8%8F%9C%E5%8D%95.html
        };
      },
    },
  },
  data() {
    return {
      editor: null,
      detLength: 0,
      value: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initEditor();
    });
  },

  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  watch: {},
  methods: {
    initEditor() {
      const editor = new E(document.getElementById("div1"));
      editor.config.placeholder = this.config.placeholder;
      editor.config.onchange = (newHtml) => {
        this.value = editor.txt.text();
        // if (this.value.length > 3000) {
        //   this.$message.warn("输入内容不能超过3000个字符！");
        //   this.value = this.value.substring(0, 3000);
        //   this.editor.txt.text(this.value);
        // }
        this.$nextTick(() => {
          this.detLength = this.value.length;
        });
        this.$emit("onchange", newHtml);
      };
      // 配置菜单栏，设置不需要的菜单
      editor.config.excludeMenus = this.config.excludeMenus;
      editor.create();
      editor.txt.html(this.config.content);
      //  editor.txt.append(this.config.content);
      this.editor = editor;
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      let data = this.editor.txt.html();
      this.$emit("getEditorData", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.notice_number {
  position: absolute;
  left: 650px;
  top: 350px;
  z-index: 10001;
  width: 130px;
  text-align: right;
}
.rich_contant {
  position: relative;
}
</style>