<template>
  <div>
    <div class="search_container">
      <div>
        <span>标题：</span>
        <a-input    @keydown.native.enter="handleSearch" class="input_width" placeholder="" v-model="title" />
      </div>
      <div>
        <span>类型：</span>
        <a-select v-model="type" class="input_width">
          <a-select-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>创建时间：</span>
        <a-range-picker
          v-model="createTime"
          class="input_width"
          @change="onTimeChange"
        />
      </div>
      <div>
        <span>创建人员：</span>
        <SelectScroll
          @getSelectId="getSelectId($event)"
          :config="selectConfig"
          v-model="createUser"
        ></SelectScroll>
      </div>
      <div>
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div class="add_button">
      <a-button @click="openModel('add')" type="primary"> 新增 </a-button>
    </div>
    <div>
      <a-table
        :loading="loading"
        style="min-width: 1500px;"
        :pagination="pagination"
        :rowKey="(record) => record.contentId"
        :columns="columns"
        :data-source="dataTable"
           :scroll="{ y: 379 }"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="openInnerModel('edit', record.type, record)">编辑</a>
          <a style="margin-left: 20px" @click="openModel('del', record)"
            >删除</a
          >
        </span>
        <span slot="type" slot-scope="text">
          <span v-if="text">{{ typeList[text - 1].name }}</span>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
    <component
      v-if="innerModelConfig.show"
      :is="componentTag"
      :config="innerModelConfig"
      @innerHandleCancel="innerHandleCancel"
      @innerHandleOk="innerHandleOk"
    ></component>
  </div>
</template>

<script>
import SelectScroll from "../../../components/selectScroll.vue";
import { contentManageApi } from "@/api/contentManage.js";
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import TopContent from "../topContent/index.vue";
import ProductNews from "../productNews/index.vue";
import CorePower from "../corePower/index.vue";
import CaseContent from "../caseContent/index.vue";
import ServiceContent from "../serviceContent/index.vue";
import UserPraise from "../userPraise/index.vue";
import ServiceAgreement from "../serviceAgreement/index.vue";
import AccountNumber from "../accountNumber/index.vue";
import OtherContent from "../otherContent/index.vue";
import osIntroduce from "../osIntroduce/index.vue";

export default {
  mixins: [pagination],
  components: {
    SelectScroll,
    ModelTag,
    CorePower,
    OtherContent,
    CaseContent,
    UserPraise,
    TopContent,
    ProductNews,
    AccountNumber,
    ServiceContent,
    ServiceAgreement,
    osIntroduce
  },
  data() {
    return {
      loading:false,
      selectConfig: {
        url: "/cloud-platform-backend-facade/front-api/v1/user/backend/page",
        params: "name",
        label: "username",
        value: "id",
        width: "240px",
        pass: true,
      },
      type: null,
      title: "",
      createUser: null,
      startTime: "",
      endTime: "",
      createTime: null,
      componentTag: "",
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
      },
      innerModelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "新增",
        width: "1200px",
        okText: "保存",
        delArr: [],
      },
      typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
         {
          name: "厘米OS介绍",
          value: 10,
        },
      ],
      columns: [
        {
          title: "展示顺序",
          dataIndex: "sort",
          key: "sort",
          align: "center",
          // width: "110px",
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          align: "center",
          // width: "110px",
        },

        {
          title: "类型",
          dataIndex: "type",
          key: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
          // width: "110px",
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          // width: "160px",
        },
        {
          title: "创建人员",
          dataIndex: "creatorName",
          key: "creatorName",
          align: "center",
          // width: "110px",
        },
        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //获取创建用户id
    getSelectId(val) {
      this.createUser = val;
    },
    innerHandleOk() {
      this.innerModelConfig.show = false;
      if (this.innerModelConfig.type === "add") {
        this.$message.success("新增成功");
      } else {
        this.$message.success("保存成功");
      }
      // this.reset();
        this.handleSearch();
    },
    //重置
    reset() {
      this.type = "";
      this.title = "";
      this.createUser = null;
      this.startTime = "";
      this.endTime = "";
      this.createTime = null;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        creatorId: this.createUser,
        startDate: this.startTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        endDate: this.endTime,
        title: this.title,
        type: this.type,
      };
      contentManageApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.startTime = dateStrings[0];
      this.endTime = dateStrings[1];
    },
    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.contentId : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "add") {
        this.modelConfig.title = "新增类型选择";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.record = record;
      } else {
        this.modelConfig.title = "删除确认";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确认";
      }
    },
    //内部弹框开启
    openInnerModel(type, tagType, record = null) {
      switch (tagType) {
        case 1:
          this.componentTag = "TopContent";
          break;
        case 2:
          this.componentTag = "ProductNews";
          break;
        case 3:
          this.componentTag = "CorePower";
          break;
        case 4:
          this.componentTag = "CaseContent";
          break;
        case 5:
          this.componentTag = "ServiceContent";
          break;
        case 6:
          this.componentTag = "UserPraise";
          break;
        case 7:
          this.componentTag = "ServiceAgreement";
          break;
        case 8:
          this.componentTag = "AccountNumber";
          break;
        case 9:
          this.componentTag = "OtherContent";
          break;
           case 10:
          this.componentTag = "osIntroduce";
          break;
      }
      this.innerModelConfig.type = type;
      if (type === "edit") {
        if (record) {
          this.innerModelConfig.id = record.contentId;
          if (record.title != "-") {
            this.innerModelConfig.title =
              record.title.length > 5
                ? record.title.slice(0, 5) + "..."
                : record.title;
          } else {
            this.innerModelConfig.title = this.typeList[tagType - 1].name;
          }
          this.innerModelConfig.record = record;
        }
      } else {
        this.innerModelConfig.title = "新增";
        this.innerModelConfig.record = "";
        this.innerModelConfig.id = null;
      }
      this.innerModelConfig.show = true;
    },
    //内部弹框关闭
    innerHandleCancel() {
      this.innerModelConfig.show = false;
    },
    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk(type = null) {
      if (type) {
        this.openInnerModel("add", Number(type));
      }
      this.modelConfig.show = false;
      if (this.modelConfig.type === "del") {
        this.$message.success("删除成功");
         this.handleSearch();
      } else {
        // this.$message.success("保存成功");
      }
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 240px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>