<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <div v-if="config.type === 'add' || config.type === 'edit'">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="类型" prop="type">
              <!-- <a-input
                class="formInputWidth"
                :disabled="true"
                v-model="form.type"
                placeholder="请输入"
              /> -->
              <a-select
                :disabled="true"
                placeholder="请输入"
                v-model="form.type"
                class="formInputWidth"
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="解决方案" prop="title">
              <a-button @click="addSolution"> 新增 </a-button>
              <div>
                <div
                  v-for="(item, index) in tempSolution"
                  :key="item.key"
                  class="solution_contain"
                >
                  <div v-if="show">
                    <SelectScroll
                      :key="item.key"
                      @getSelectId="getSelectScr($event, index)"
                      :config="selectConfig"
                      v-model="item.solutionId"
                      :defaultData="defaultData"
                    ></SelectScroll>
                  </div>
                  <div style="margin-left: 20px">
                    <a-button @click="delSolution(index)"> 删除 </a-button>
                  </div>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="合作伙伴">
              <div class="notice_text">
                建议尺寸：252px*96px，支持格式：PNG、JPG或JPEG，单个文件不超2M
              </div>
              <div class="upload_Content">
                <UploadTag
                  :config="uploadConfig"
                  @getImageUrl="getImageUrl"
                ></UploadTag>
              </div>
            </a-form-model-item>
            <a-form-model-item label="底部二维码">
              <div class="notice_text">
                建议尺寸：140px*140px，支持格式：PNG、JPG或JPEG，单个文件不超2M
              </div>
              <div class="upload_Content">
                <UploadTag
                  @getImageUrl="getImageUrl2"
                  :config="uploadConfig2"
                ></UploadTag>
              </div>
            </a-form-model-item>
            <a-form-model-item label="座机" prop="landline">
              <a-input
                class="formInputWidth"
                v-model="form.landline"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="手机" prop="phone">
              <a-input
                class="formInputWidth"
                v-model="form.phone"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="QQ" prop="qq">
              <a-input
                class="formInputWidth"
                v-model="form.qq"
                placeholder="请输入"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import UploadTag from "../../../components/uploadTag.vue";
import SelectScroll from "../../../components/selectScroll.vue";
import { contentManageApi } from "@/api/contentManage.js";

export default {
  components: {
    UploadTag,
    SelectScroll,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验QQ
    let validatorQQ = (rule, value, callback) => {
      if (value.length > 20) {
        callback(new Error("字符长度不能超过20！"));
      } else if (!/^[1-9][0-9]{4,}$/.test(value) && value) {
        callback(new Error("QQ号码格式不正确!"));
      } else {
        callback();
      }
    };
    //校验手机号
    let validatorPhone = (rule, value, callback) => {
      if (value.length > 20) {
        callback(new Error("字符长度不能超过20！"));
      } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
        callback(new Error("手机号码格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      typeList: [
        {
          name: "顶部内容",
          value: 1,
        },
        {
          name: "产品动态",
          value: 2,
        },
        {
          name: "核心能力",
          value: 3,
        },
        {
          name: "案例",
          value: 4,
        },
        {
          name: "服务",
          value: 5,
        },
        {
          name: "用户好评",
          value: 6,
        },
        {
          name: "用户服务协议",
          value: 7,
        },
        {
          name: "汇款账号",
          value: 8,
        },
        {
          name: "其他",
          value: 9,
        },
      ],
      spinning: false,
      confirmLoading: false,
      solutionVal: null,
      editId: null,
      show: true,
      defaultData: [],
      uploadConfig: {
        barrelType: "public",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 2, //限制文件大小
        // limitLength: 4, //限制上传文件数量
        limitControl: false, //是否限制照片墙数量
        fileList: [], //文件列表
      },
      uploadConfig2: {
        barrelType: "public",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 2, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
      },
      selectConfig: {
        
        url: "/cloud-platform-backend-facade/front-api/v1/content/solution",
        params: "name",
        label: "solutionName",
        value: "solutionId",
        width: "416px",
        pass: true,
      },
      tempSolution: [],
      qq: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      detLength: 0,
      form: {
        solutionId: [],
        type: 9,
        partners: [],
        bottomQrCode: "",
        landline: "",
        phone: "",
        qq: "",
      },

      rules: {
        landline: [
          {
            max: 20,
            required: false,
            message: "字符长度不能超过20！",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: false,
            trigger: "blur",
            validator: validatorPhone,
          },
        ],
        qq: [
          {
            required: false,
            trigger: "blur",
            validator: validatorQQ,
          },
        ],
      },
      type: "",
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},
  methods: {
    initModal() {
      if (this.config.type === "add") {
        //   this.getTypeList()
      } else {
        this.getDetail();
      }
    },
    //添加解决方案
    addSolution() {
      let s = {
        solutionId: null,
        key: +new Date(),
      };
      this.tempSolution.push(s);
    },
    //删除解决方案
    delSolution(index) {
      let x = [];
      let s = this.tempSolution[index];
      this.tempSolution.forEach((v) => {
        if (v.key !== s.key) {
          x.push(v);
        }
      });
      this.tempSolution = x;
    },
    getSelectScr($event, index) {
      console.log($event);
      console.log(index);
    },
    //新增其他内容
    addOtherContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        sort: "-",
        title: "-",
        solutions: this.form.solutionId,
      };
      contentManageApi.addOtherContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //更新其他内容
    editOtherContent() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        contentId: this.config.id,
        rowVersion: this.config.record.rowVersion,
        id: this.editId,
        sort: "-",
        title: "-",
        solutions: this.form.solutionId,
      };
      contentManageApi.editOtherContent(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("innerHandleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //获取编辑详情
    getDetail() {
      // let that = this;
      this.spinning = true;
      let id = this.config.id;
      contentManageApi.getDetail(id).then((res) => {
        if (res.success) {
          this.editId = res.data.id;
          let temp = res.data.solutions;
          let s = [];
          this.defaultData = res.data.solutions;

          if (temp.length > 0) {
            temp.forEach((v, index) => {
              s.push({
                solutionId: v.solutionId,
                key: +new Date() + index,
              });
            });
          }
          this.tempSolution = s;
          this.form.partners = res.data.partners;
          this.form.qq = res.data.qq;
          this.form.landline = res.data.landline;
          this.form.phone = res.data.phone;
          this.form.bottomQrCode = res.data.bottomQrCode;

          if (res.data.partners.length > 0) {
            console.log("返回的合作伙伴");
            console.log(res.data.partners);
            res.data.partners.forEach((v) => {
              this.uploadConfig.fileList.push({
                name: v.substring(v.lastIndexOf("com") + 4),
                uid: v,
                status: "done",
                url: v,
              });
            });
          }
          if (res.data.bottomQrCode.length > 0) {
            this.uploadConfig2.fileList.push({
              name: res.data.bottomQrCode.substring(
                res.data.bottomQrCode.lastIndexOf("/") + 1
              ),
              uid: res.data.bottomQrCode,
              status: "done",
              url: res.data.bottomQrCode,
            });
          }
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    //上传组件的回调结果
    getImageUrl(val, fileList) {
      let s = [];
      console.log("合作伙伴回调");
      console.log(fileList);
      fileList.forEach((v) => {
        s.push(v.uid);
      });
      this.uploadConfig.fileList = fileList;
      this.form.partners = s;
    },
    getImageUrl2(val, fileList) {
      console.log(fileList);
      if (val) {
        this.form.bottomQrCode = val;
      } else {
        this.form.bottomQrCode = "";
      }
      this.uploadConfig2.fileList = fileList;
    },

    // 详情输入事件
    textOnchange() {
      if (this.form.details.length > 500) {
        this.form.details = this.form.details.substring(0, 500);
      }
      this.$nextTick(() => {
        this.detLength = this.form.details.length;
      });
    },

    //检查解决方案是否重复
    checkOnly(data) {
      let newArr = data.map((item) => item.solutionId);
      let arrSet = new Set(newArr);
      return arrSet.size === newArr.length;
    },
    handleOk() {
      if (this.tempSolution.length !== 0) {
        let bool = this.tempSolution.some(
          (v) => v.solutionId === undefined || v.solutionId === null
        );
        if (bool) {
          this.$message.warn("已新增解决方案不能为空！");
        } else {
          let result = this.checkOnly(this.tempSolution);
          if (result) {
            this.form.solutionId = [];
            this.tempSolution.forEach((v) => {
              this.form.solutionId.push(v.solutionId);
            });
            if (this.config.type === "add") {
              this.addOtherContent();
            } else {
              this.editOtherContent();
            }
          } else {
            this.$message.warn("已新增解决方案不能重复！");
          }
        }
      } else {
        if (this.config.type === "add") {
          this.addOtherContent();
        } else {
          this.editOtherContent();
        }
      }
    },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_Content {
  min-height: 110px;
  // width: 700px;
  // display: flex;
  // flex-flow: wrap;
  // background-color: aqua;
}
.solution_contain {
  display: flex;
}
.formContent {
  padding-left: 110px;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>